import { authHeader, authKioskHeader, ApiConfigs } from '../_helpers';

export const kioskService = {
    getAll,
    getPage,
    filter,
    getById,
    update,
    create,
    resetPassword,
    delete: _delete,
    login,
    autoLogin,
    logout,
    findCustomerByPhoneNumber,
    createCustomer,
    getQueueById,
    joinQueue,
    refresh,
    refreshkiosk,
    ping,
    createBooking,
    getHolidays,
    checkInternet,
    generateAuthCode
};

function getHolidays() {
    const requestOptions = {
        method: 'GET',
        credentials : 'include',
        headers: authKioskHeader()
    };
    return fetch(`${ApiConfigs.base_kiosk_url + ApiConfigs.kiosks.getHolidays}`, requestOptions).then(handleResponse).catch(handleRejected);
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        credentials : 'include',
        headers: authHeader()
    };
    return fetch(`${ApiConfigs.base_url + ApiConfigs.kiosks.getAll}`, requestOptions).then(handleResponse).catch(handleRejected);
}


function getPage(page) {
    const requestOptions = {
        method: 'GET',
        credentials : 'include',
        headers: authHeader()
    };
    return fetch(`${ApiConfigs.base_url + ApiConfigs.kiosks.pagination + page}`, requestOptions).then(handleResponse).catch(handleRejected);
}


function filter(keyword) {
    const requestOptions = {
        method: 'POST',
        credentials : 'include',
        headers: authHeader()
    };
    return fetch(`${ApiConfigs.base_url + ApiConfigs.kiosks.filter.replace(':keyword', keyword)}`, requestOptions).then(handleResponse).catch(handleRejected);
}


function getById(id) {
    const requestOptions = {
        method: 'GET',
        credentials : 'include',
        headers: authHeader()
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.kiosks.get.replace(':id', id)}`, requestOptions).then(handleResponse).catch(handleRejected);
}

function update(id, kiosk) {
    const requestOptions = {
        method: 'POST',
        credentials : 'include',
        headers: { ...authHeader() },
        body: kiosk
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.kiosks.update.replace(':id', id)}`, requestOptions).then(handleResponse).catch(handleRejected);
}

function resetPassword(id) {
    const requestOptions = {
        method: 'POST',
        credentials : 'include',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: {}
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.kiosks.resetPassword.replace(':id', id)}`, requestOptions).then(handleResponse).catch(handleRejected);
}

function create(kiosk) {
    const requestOptions = {
        method: 'POST',
        credentials : 'include',
        headers: { ...authHeader() },
        body: kiosk
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.kiosks.create}`, requestOptions).then(handleResponse).catch(handleRejected);
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        credentials : 'include',
        headers: authHeader()
    };

    return fetch(`${ApiConfigs.base_url + ApiConfigs.kiosks._delete.replace(':id', id)}`, requestOptions).then(handleResponse).catch(handleRejected);
}

function findCustomerByPhoneNumber(phone) {
    const requestOptions = {
        method: 'POST',
        credentials : 'include',
        headers: { ...authKioskHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(phone)
    };

    return fetch(`${ApiConfigs.base_kiosk_url + ApiConfigs.kiosks.findCustomerByPhone}`, requestOptions).then(handleResponse).catch(handleRejected);
}

function createCustomer(customer) {
    const requestOptions = {
        method: 'PUT',
        credentials : 'include',
        headers: { ...authKioskHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(customer)
    };

    return fetch(`${ApiConfigs.base_kiosk_url + ApiConfigs.kiosks.createCustomer}`, requestOptions).then(handleResponse).catch(handleRejected);
}

function joinQueue(data) {
    const requestOptions = {
        method: 'PUT',
        credentials : 'include',
        headers: { ...authKioskHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    return fetch(`${ApiConfigs.base_kiosk_url + ApiConfigs.kiosks.joinQueue}`, requestOptions).then(handleResponse).catch(handleRejected);
}

// function createBooking(data) {
//     const requestOptions = {
//         method: 'POST',
//         headers: { ...authKioskHeader()},
//         body: data
//     };
//     return fetch(`${ApiConfigs.base_kiosk_url + ApiConfigs.kiosks.createBooking}`, requestOptions).then(handleResponse);
// }

function createBooking(data) {
    const requestOptions = {
        method: 'PUT',
        credentials : 'include',
        headers: { ...authKioskHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };
    return fetch(`${ApiConfigs.base_kiosk_url + ApiConfigs.kiosks.createBooking}`, requestOptions).then(handleResponse).catch(handleRejected);
}

function login(key, secret) {
    const requestOptions = {
        method: 'POST',
        credentials : 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ key, secret })
    };

    return fetch(`${ApiConfigs.base_kiosk_url + ApiConfigs.kiosks.login}`, requestOptions)
        .then(handleResponse)
        .then(kiosk => {
            if (localStorage.getItem("kiosk") != JSON.stringify(kiosk)) {
                localStorage.setItem('kiosk', JSON.stringify(kiosk));
                console.log('reloading');
                location.reload();
            }
            return kiosk;
        }).catch(handleRejected);
}

function autoLogin(key, token) {
    const requestOptions = {
        method: 'POST',
        credentials : 'include',
        headers: { 'Content-Type': 'application/json' }
    };

    return fetch(`${ApiConfigs.base_kiosk_url + ApiConfigs.kiosks.autoLogin
        .replace(':key', key).replace(':token', token)}`, requestOptions)
        .then(handleResponse)
        .then(kiosk => {
            if (localStorage.getItem("kiosk") != JSON.stringify(kiosk)) {
                localStorage.setItem('kiosk', JSON.stringify(kiosk));
                console.log('reloading');
                location.reload();
            }
            return kiosk;
        }).catch(handleRejected);
}

function logout() {
    // remove user from local storage to log user out
    const requestOptions = {
        method: 'POST',
        credentials : 'include',
        headers: { ...authKioskHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(`${ApiConfigs.base_kiosk_url + ApiConfigs.kiosks.logout}`, requestOptions)
        .then(handleResponse)
        .then(function () {
            localStorage.removeItem('kiosk');
        }).catch(handleRejected);
}

function getQueueById(id) {
    const requestOptions = {
        method: 'GET',
        credentials : 'include',
        headers: authKioskHeader()
    };

    return fetch(`${ApiConfigs.base_kiosk_url + ApiConfigs.queues.get.replace(':id', id)}`, requestOptions).then(handleResponse).catch(handleRejected);
}

function refresh() {
    const requestOptions = {
        method: 'GET',
        credentials : 'include',
        headers: { ...authKioskHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(`${ApiConfigs.base_kiosk_url + ApiConfigs.kiosks.refresh}`, requestOptions)
        .then(handleResponse)
        .then(kiosk => {
            localStorage.setItem('kiosk', JSON.stringify(kiosk));
            return kiosk;
        }).catch(handleRejected);
}

function ping(payload) {
    const requestOptions = {
        method: "POST",
        credentials : 'include',
        headers: {...authKioskHeader() },
        body: payload
    }

    return fetch(`${ApiConfigs.base_kiosk_url + ApiConfigs.kiosks.ping}`, requestOptions)
        .then(handleResponse).catch(handleRejected);
}

function refreshkiosk() {
    const requestOptions = {
        method: 'GET',
        credentials : 'include',
        headers: { ...authKioskHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(`${ApiConfigs.base_kiosk_url + ApiConfigs.kiosks.refresh}`, requestOptions)
        .then(handleResponse)
        .then(kiosk => {
            localStorage.setItem('kiosk', JSON.stringify(kiosk));
        }).catch(handleRejected);
}


function checkInternet() {
    const requestOptions = {
        method: 'GET',
        credentials : 'include',
        headers: { ...authKioskHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(`${ApiConfigs.base_kiosk_url + ApiConfigs.kiosks.refresh}`, requestOptions)
        .then(handleResponse)
        .catch(handleRejected);
}

function generateAuthCode() {
    const requestOptions = {
        method: 'GET',
        credentials : 'include',
        headers: { ...authKioskHeader() }
    }

    return fetch(`${ApiConfigs.base_kiosk_url + ApiConfigs.kiosks.generateAuthCode}`, requestOptions)
        .then(handleResponse)
        .catch(handleRejected);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                localStorage.removeItem('kiosk');
                // auto logout if 401 response returned from api
                location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}


function handleRejected(exception) {
    console.log(exception);
}