import Vue from "vue";
import App from "./App.vue";
// import {ApiConfigs, authHeader} from './_helpers'
//Bootstrap
import {BootstrapVue, IconsPlugin} from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import {ApiConfigs, authKioskHeader} from "./_helpers";

// Vuex Store
import {store} from "./_store";

//Fontawesome
import {library} from "@fortawesome/fontawesome-svg-core";
import {
    faBuilding,
    faCaretLeft,
    faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import {faCaretRight} from "@fortawesome/free-solid-svg-icons";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

library.add(faCaretLeft);
library.add(faCaretRight);
library.add(faTimes);
library.add(faBuilding);
library.add(faChevronDown);
// i18n
import i18n from "./i18n/i18n.js";

if (!localStorage.getItem("Language")) {
    localStorage.setItem("Language", "en");
}
// Vue Router
import {router} from "./_helpers";

import CountryFlag from "vue-country-flag";

Vue.component("country-flag", CountryFlag);

Vue.config.productionTip = false;

try {
    // set font & colors
    let fullConfig = JSON.parse(localStorage.getItem("kiosk"));

    if (fullConfig != null) {
        var config = JSON.parse(fullConfig.kiosk.config);
        let bodyStyles = document.body.style;
        bodyStyles.setProperty("--primary", config.primaryDark);
        bodyStyles.setProperty("--primary-medium", config.primaryMedium);
        bodyStyles.setProperty("--primary-light", config.primaryLight);

        bodyStyles.setProperty("--secondary", config.secondary);
        bodyStyles.setProperty("--info", config.info);
    }

    Vue.mixin({
        data: function () {
            return {
                mixin: null,
                get Config() {
                    this.mixin = fullConfig;
                    return this.mixin;
                },
            };
        },
    });
} catch (err) {
    console.log(err)
}

import '@/assets/css/style.scss'

if (i18n.locale == "ar") {
    import('@/assets/css/ar.scss').then(() => {
        console.log("Arabic display");
    })
}
// let fullConfig = JSON.parse(localStorage.getItem("kiosk"));

// if (fullConfig != null) {
//   var config = JSON.parse(fullConfig.kiosk.config);
//   let bodyStyles = document.body.style;
//   bodyStyles.setProperty("--primary", config.primaryDark);
//   bodyStyles.setProperty("--primary-medium", config.primaryMedium);
//   bodyStyles.setProperty("--primary-light", config.primaryLight);

//   bodyStyles.setProperty("--secondary", config.secondary);
//   bodyStyles.setProperty("--info", config.info);
// }

// Vue.mixin({
//   data: function() {
//     return {
//       mixin: null,
//       get Config() {
//         this.mixin = fullConfig;
//         return this.mixin;
//       },
//     };
//   },
// });
new Vue({
    store,
    router,
    i18n,
    render: (h) => h(App),
}).$mount("#app");

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.config.productionTip = false;
// Moment
import moment from "moment";
import "moment-timezone";

moment.locale(i18n.locale == "ar" ? "ar-tn" : i18n.locale);
Vue.prototype.$moment = moment;

// import Pusher from 'pusher-js' // import Pusher
// Vue.prototype.$pusher = new Pusher(process.env.VUE_APP_PUSHER_ID, {
//   cluster: 'eu',
//   encrypted: true,
//   authEndpoint: ApiConfigs.broadcast_url+ApiConfigs.pusher.auth,
//   auth: {
//       headers: { ...authHeader() }
//   }
// });

localStorage.setItem("originalHost", process.env.VUE_APP_API_URL_TN);

// pusher part
import Echo from "laravel-echo";

window.Pusher = require("pusher-js");

Vue.prototype.$pusher = new Echo({
    broadcaster: "pusher",
    key: process.env.VUE_APP_PUSHER_KEY,
    forceTLS: false,
    wsHost: process.env.VUE_APP_WSS_URL,
    wsPort: 6001,
    disableStats: false,
    authEndpoint: ApiConfigs.broadcast_url + ApiConfigs.pusher.auth,
    enabledTransports: ["ws", "wss"],
    auth: {
        headers: {...authKioskHeader()},
    },
});

// declare duration between each ping
Vue.prototype.$pingInterval = process.env.VUE_APP_PING_INT;
