import Vue from 'vue';
import Vuex from 'vuex';
import moment from "moment";

import { alert } from './alert.module';
//import { account } from './account.module';
import { Kiosk } from './kiosk.module';
//import { users } from './users.module';



Vue.use(Vuex)
/* eslint-disable */
export const store = new Vuex.Store({
    modules: {
        alert,
        Kiosk
    },
    state: {

        // Calendar Events
        calendarEvents: [{
            title: "test",
            allDay: true,
            start: moment(),
            end: moment().add(1, "d")
        }],

        // Email States
        filtered_mail: "Inbox",
        pingInterval: null,
        pingSentStatus: false

    },
    mutations: {
        //This is for Sidbar trigger in mobile
        IS_SIDEBAR_ACTIVE(state, value) {
            state.isSidebarActive = value;
        },
        //This is for Sidebar toggle in desktop
        TOGGLE_REDUCE_SIDEBAR(state, val) {
            state.isSidebarReduced = val;
        },
        //This is for Themeset
        SET_THEME(state, val) {
            state.theme = val;
        },
        //This is for Theme color
        SET_THEME_COLOR(state, val) {
            state.themeColor = val;
        },
        SET_SIDEBAR_WIDTH(state, width) {
            state.sidebarWidth = width;
        },
        // Add Calendar event
        ADD_CALENDAR_EVENT(state, event) {
            const newEvent = Object.assign({}, event);
            state.calendarEvents.push(newEvent);
        },
        set_pingInterval(state, pingInterval) {
            state.pingInterval = pingInterval;
          },
          clear_pingInterval(state) {
            clearInterval(state.pingInterval);
            state.pingInterval = null;
          },
          update_pingSentStatus(state, status) {
            state.pingSentStatus = status;
          },
    },
    actions: {
        updateSidebarWidth({ commit }, width) {
            commit('SET_SIDEBAR_WIDTH', width);
        },
        //Calendar Action
        addCalendarEvent({ commit }, event) {
            commit('ADD_CALENDAR_EVENT', event);
        },

        // Email Action
        filteredMail({ commit }, filterCategory){
            commit('FILTERED_MAIL', filterCategory)
        },
        setPingInterval({ commit }, pingInterval){
            commit('set_pingInterval', pingInterval)
        },
    
        clearPingInterval({ commit }){
            commit('clear_pingInterval')
        },
        updatePingSentStatus({ commit }, status){
            commit('update_pingSentStatus', status)
        },
        

    },
    getters:{
        getPingSentStatus(state) {
            return state.pingSentStatus;
        }
      }
})